import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SEO from "../../components/seo";
import { Helmet } from "react-helmet"

class Voyager extends Component {
  componentDidMount() {
    window.location = "https://projects.birk.com.au/auth/login";
  }

  render() {

    return (
      <React.Fragment>
        <SEO />
        <Helmet>
          <link rel="canonical" href="http://birk.com.au" />
        </Helmet>
        <Header />
        <ToastContainer />
        <section className="folio-section">
          <h1>Redirecting you, please wait...</h1>
          <a href="https://projects.birk.com.au/auth/login">if that doesn't work, please click here.</a>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
export default Voyager;
